<template>
  <v-simple-table>
    <thead>
      <tr>
        <th style="width: 13%" class="text-center">{{ $t('orders.headers.number') }}</th>
        <th style="width: 25%" class="text-center">{{ $t('orders.headers.loading') }}</th>
        <th style="width: 25%" class="text-center">{{ $t('orders.headers.transit') }}</th>
        <th style="width: 25%" class="text-center">{{ $t('orders.headers.delivering') }}</th>
        <th style="width: 12%" class="text-center">{{ $t('orders.headers.total') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="order in orders"
        :key="`order-${order.pk}`"
      >
        <td style="line-height: 15px" class="text-center">
          <b>{{ order.number }}</b>
          <br>
          <small>{{ getCarrier(order) }}</small>
          <br>
          <small>{{ getCustomer(order) }}</small>
        </td>

        <td style="height: 80px; padding: 1px;">
          <div
            style="line-height: 15px; height: 100%;"
            :class="{
              'white--text text-center d-flex flex-column align-center justify-center': true,
              'grey darken-2': getStageStatus(order, 'LOADING') === 'PENDING',
              'primary': getStageStatus(order, 'LOADING') === 'STARTED',
              'secondary': getStageStatus(order, 'LOADING') === 'FINISHED',
            }"
          >
            <b>{{ calculateTime(order, 'LOADING') }}</b>
            <small>
              <v-tooltip v-if="!hasPlantGeofence(order)" bottom>
                <template #activator="{ on }">
                  <v-icon color="white" x-small v-on="on">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.geofenceNotLinked') }}</span>
              </v-tooltip>
              {{ getPlant(order) }}
            </small>
          </div>
        </td>

        <td style="height: 80px; padding: 1px;">
          <div
            style="line-height: 15px; height: 100%;"
            :class="{
              'white--text text-center d-flex flex-column align-center justify-center': true,
              'grey darken-2': getStageStatus(order, 'TRANSIT') === 'PENDING',
              'primary': getStageStatus(order, 'TRANSIT') === 'STARTED',
              'secondary': getStageStatus(order, 'TRANSIT') === 'FINISHED',
            }"
          >
            <b>{{ calculateTime(order, 'TRANSIT') }}</b>
            <small>
              <v-tooltip v-if="!hasUnit(order)" bottom>
                <template #activator="{ on }">
                  <v-icon color="white" x-small v-on="on">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.unitNotLinked') }}</span>
              </v-tooltip>
              {{ getUnit(order) }}
            </small>
          </div>
        </td>

        <td style="height: 80px; padding: 1px;">
          <div
            style="line-height: 15px; height: 100%;"
            :class="{
              'white--text text-center d-flex flex-column align-center justify-center': true,
              'grey darken-2': getStageStatus(order, 'DELIVERING') === 'PENDING',
              'primary': getStageStatus(order, 'DELIVERING') === 'STARTED',
              'secondary': getStageStatus(order, 'DELIVERING') === 'FINISHED',
            }"
          >
            <b>{{ calculateTime(order, 'DELIVERING') }}</b>
            <small>
              <v-tooltip v-if="!hasDestinationGeofence(order)" bottom>
                <template #activator="{ on }">
                  <v-icon color="white" x-small v-on="on">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.geofenceNotLinked') }}</span>
              </v-tooltip>
              {{ getDestination(order) }}
            </small>
          </div>
        </td>

        <td class="text-center">
          <b>{{ calculateFullTime(order, stages) }}</b>
          <br>
          <v-btn x-small icon color="primary" @click="$emit('show-map', order)">
            <v-icon small>mdi-map</v-icon>
          </v-btn>

          <v-btn v-if="session.level === 'ADMIN'" x-small icon color="primary" @click="$emit('cancel-order', order.pk)">
            <v-icon small>mdi-close-circle-outline</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => {}
    },
    now: {
      type: Number,
      default: () => new Date() / 1000
    }
  },

  computed: {
    stages () {
      return ['LOADING', 'TRANSIT', 'DELIVERING']
    }
  },

  methods: {
    hasPlantGeofence (order) { return !!order?.plant?.geofence?.pk },
    hasDestinationGeofence (order) { return !!order?.destination?.geofence?.pk },
    hasUnit (order) { return !!order?.unit?.ident },

    getCarrier (order) {
      return order?.unit?.carrier?.name || this.$t('helpers.notAvailable')
    },

    getCustomer (order) {
      return order?.destination?.owner?.name || this.$t('helpers.notAvailable')
    },

    getUnit (order) {
      return order?.unit?.plate || this.$t('helpers.notAvailable')
    },
    
    getDriver (order) {
      return order?.driver?.name || this.$t('helpers.notAvailable')
    },

    getPlant (order) {
      return order?.plant?.name || this.$t('helpers.notAvailable')
    },

    getDestination (order) {
      return order?.destination?.name || this.$t('helpers.notAvailable')
    },

    generateLabel (order, state) {
      switch (state) {
        case 'LOADING':
          return this.$t('orders.stages.LOADING', { name: order.plant?.name || this.$t('helpers.notAvailable') })
        case 'TRANSIT':
          return this.$t('orders.stages.TRANSIT', { name: order.destination?.name || this.$t('helpers.notAvailable') })
        case 'DELIVERING':
          return this.$t('orders.stages.DELIVERING', { name: order.destination?.name || this.$t('helpers.notAvailable') })
        default:
          return this.$t('orders.waitingToStart')
      }
    },

    getStageStatus (order, state) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (!order?.stage) {
        return 'PENDING'
      }
      
      if (!order.stage[field]) {
        return 'PENDING'
      }

      if (order.stage[field].endAt) {
        return 'FINISHED'
      }
      if (order.stage[field].startAt) {
        return 'STARTED'
      }
      return 'PENDING'
    },

    calculateTime (order, state, getTime = false) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (!order?.stage) {
        if (getTime) {
          return 0
        }
        return '00D 00H 00M'
      }
      
      if (!order.stage[field]) {
        if (getTime) {
          return 0
        }
        return '00D 00H 00M'
      }

      if (order.stage[field].endAt) {
        const diff = Math.round(parseInt(order.stage[field].endAt - order.stage[field].startAt) / 60)

        const days = parseInt((diff / 60) / 24).toString().padStart(2, '0')
        const hours = parseInt((diff / 60) % 24).toString().padStart(2, '0')
        const minutes = parseInt(diff % 60).toString().padStart(2, '0')

        if (getTime) {
          return diff
        }
        return `${days}D ${hours}H ${minutes}M`
      }
      if (order.stage[field].startAt) {
        const diff = Math.round(parseInt(this.now - order.stage[field].startAt) / 60)

        const days = parseInt((diff / 60) / 24).toString().padStart(2, '0')
        const hours = parseInt((diff / 60) % 24).toString().padStart(2, '0')
        const minutes = parseInt(diff % 60).toString().padStart(2, '0')

        if (getTime) {
          return diff
        }
        return `${days}D ${hours}H ${minutes}M`
      }
      if (getTime) {
        return 0
      }
      return '00D 00H 00M'
    },

    calculateFullTime (order, stages) {
      let time = 0
      for (const i in stages) {
        time += this.calculateTime(order, stages[i], true)
      }

      const days = parseInt((time / 60) / 24).toString().padStart(2, '0')
      const hours = parseInt((time / 60) % 24).toString().padStart(2, '0')
      const minutes = parseInt(time % 60).toString().padStart(2, '0')

      return `${days}D ${hours}H ${minutes}M`
    }
  }
}
</script>