var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.order.number)+" ")]),(_vm.session.level !== 'USER')?_c('v-card-subtitle',{staticClass:"d-flex flex-column align-start"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[_vm._v(_vm._s(_vm.carrier))])]}}],null,false,3070240553)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.carrier')))])]),_c('v-divider',{staticClass:"mx-1 my-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[_vm._v(_vm._s(_vm.customer))])]}}],null,false,1433440081)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.customer')))])]),_c('v-divider',{staticClass:"mx-1 my-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[(!_vm.hasUnit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","x-small":""}},on2),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.unitNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.unit)+" ")],1)]}}],null,false,660153610)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.unit')))])]),_c('v-divider',{staticClass:"mx-1 my-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[_vm._v(_vm._s(_vm.driver))])]}}],null,false,2380218635)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.driver')))])])],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[(!_vm.hasPlantGeofence)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","x-small":""}},on2),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.geofenceNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.plant)+" ")],1)]}}],null,false,738419308)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.plant')))])]),_c('v-divider',{staticClass:"mx-1 my-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[(!_vm.hasDestinationGeofence)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","x-small":""}},on2),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.geofenceNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.destination)+" ")],1)]}}],null,false,2145735628)},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.destination')))])])],1)]):_c('v-card-subtitle',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticStyle:{"font-size":"12px"}},on),[_vm._v(_vm._s(_vm.destination))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('orders.destination')))])])],1),_c('v-divider',{staticClass:"mx-2"}),_c('v-card-text',[(_vm.order.state !== 'PENDING')?_c('v-row',_vm._l((_vm.stages),function(stage){return _c('v-col',{key:("order-" + (_vm.order.pk) + "-stage-" + stage),staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('p',{staticClass:"text-center ma-0 pa-0",domProps:{"innerHTML":_vm._s(_vm.generateLabel(stage))}}),(_vm.getStageStatus(stage) === 'PENDING')?[_c('v-icon',{attrs:{"size":"60"}},[_vm._v("mdi-progress-clock")])]:(_vm.getStageStatus(stage) === 'STARTED')?[_c('v-progress-circular',{staticStyle:{"margin":"5px 0"},attrs:{"indeterminate":"","size":"50","width":"10","color":"secondary"}})]:[_c('v-icon',{attrs:{"color":"success","size":"60"}},[_vm._v("mdi-check-circle-outline")])],_c('p',[_vm._v(_vm._s(_vm.calculateTime(stage)))])],2)}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('orders.waitingToStart')))])])],1)],1),_c('v-divider',{staticClass:"mx-2"}),_c('v-card-actions',[(_vm.order.state !== 'PENDING')?_c('v-chip',{attrs:{"label":"","small":"","color":"secondary black--text"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(_vm.calculateFullTime(_vm.stages))+" ")],1):_vm._e(),_c('v-spacer'),(_vm.session.level !== 'USER')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('show-map', _vm.order)}}},[_c('v-icon',[_vm._v("mdi-map")])],1):_vm._e(),(_vm.session.level === 'ADMIN')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('cancel-order', _vm.order.pk)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }