<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('orders.title.monitor') }}</v-card-title>
          <v-card-text>
            <v-row v-if="session.level !== 'USER'">
              <v-col cols="12" sm="6" lg="3">
                <v-select
                  v-model="mode"
                  :items="['CLASSIC', 'MODERN']"
                  depressed
                  hide-details
                  solo
                >
                  <template #item="{ item }">
                    {{ $t(`monitor.toolbar.displayMode.${item}`) }}
                  </template>
                  <template #selection="{ item }">
                    {{ $t(`monitor.toolbar.displayMode.${item}`) }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-select
                  v-model="searchSelector"
                  :items="['ALL', 'PENDING', 'LOADING', 'TRANSIT', 'DELIVERING']"
                  depressed
                  hide-details
                  solo
                >
                  <template #item="{ item }">
                    {{ $t(`monitor.toolbar.search.state.${item}`) }}
                  </template>
                  <template #selection="{ item }">
                    {{ $t(`monitor.toolbar.search.state.${item}`) }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="searchText"
                  depressed
                  :placeholder="$t('monitor.toolbar.search.text')"
                  prepend-icon="mdi-magnify"
                  hide-details
                  solo
                />
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-btn depressed block color="primary" class="mt-1" @click="moreFiltersDialog = true">
                  {{ $t('monitor.toolbar.moreFilters.title') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="currentMode === 'MODERN'">
              <v-col
                v-for="order in filteredOrders"
                :key="`order-${order.pk}`"
                cols="12"
                lg="12"
                xl="6"
              >
                <v-modern-order
                  :order="order"
                  :now="now"
                  @show-map="showInMap"
                  @cancel-order="cancelOrder"
                />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12">
                <v-classic-order
                  :orders="filteredOrders"
                  :now="now"
                  @show-map="showInMap"
                  @cancel-order="cancelOrder"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="mapDialog"
      persistent
      width="90vw"
    >
      <v-card style="height: 90vh;">
        <div style="position: absolute; top: 10px; left: 10px; z-index: 2; display: flex; flex-direction: column;">
          <v-btn
            x-small
            fab
            elevation="2"
            color="white primary--text"
            class="my-1"
            @click="zoomIn"
          >
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>

          <v-btn
            x-small
            fab
            elevation="2"
            color="white primary--text"
            class="my-1"
            @click="zoomOut"
          >
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
        </div>

        <div style="position: absolute; top: 10px; right: 10px; z-index: 2; display: flex; flex-direction: column;">
          <v-btn
            x-small
            fab
            elevation="2"
            color="white primary--text"
            class="my-1"
            @click="closeMap"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div id="map" style="width: 100%; height: 100%; z-index: 1;" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moreFiltersDialog"
      persistent
      width="50%"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="moreFilters.byDate"
                :label="$t('monitor.toolbar.moreFilters.byDate')"
                hide-details
              />
            </v-col>

            <v-col v-if="moreFilters.byDate" cols="12">
              <v-date-range-picker
                v-model="moreFilters.dateRange"
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn block color="primary" depressed @click="moreFiltersDialog = false">{{ $t('actions.apply') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { DateRange } from 'vuetify-dates-pickers'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Modern, Classic } from '@/components/Orders'

export default {
  components: {
    'v-modern-order': Modern,
    'v-classic-order': Classic,
    'v-date-range-picker': DateRange
  },
  data () {
    return {
      mapDialog: false,
      map: null,
      layer: null,
      remainingPathToDestination: null,

      moreFiltersDialog: false,
      moreFilters: {
        byDate: false,
        dateRange: [
          new Date(),
          new Date()
        ]
      },

      searchText: '',
      searchSelector: 'ALL',
      currentMode: 'MODERN',
      now: new Date() / 1000
    }
  },

  computed: {
    session () { return this.$store.state.session },

    mode: {
      get () { return this.currentMode },
      set (value) {
        this.currentMode = value
        localStorage.setItem('monitor.mode', value)
      }
    },

    filteredOrders () {
      let orders = this.$store.state.orders.list.filter(order => {
        return !['CANCELLED', 'DELIVERED'].includes(order.state)
      })
      if (this.searchSelector !== 'ALL') {
        orders = orders.filter(item => item.state === this.searchSelector)
      }

      if (this.moreFilters.byDate) {
        const startAt = this.moreFilters.dateRange[0].getTime() / 1000
        const endAt = this.moreFilters.dateRange[1].getTime() / 1000

        orders = orders.filter(item => {
          return item.dispatchAt >= startAt && item.dispatchAt <= endAt
        })
      }

      if (this.searchText !== '') {
        orders = orders.filter(item => {
          const customer = item?.destination?.owner?.name || ''
          const plant = item?.plant?.name || ''
          const destination = item?.destination?.name || ''
          const unit = item?.unit?.plate || ''
          const driver = item?.driver?.name || ''
          const carrier = item?.unit?.carrier?.name || ''
          const order = item?.number || ''

          const search = this.searchText.toLowerCase()
          return (
            customer.toLowerCase().includes(search) ||
            plant.toLowerCase().includes(search) ||
            destination.toLowerCase().includes(search) ||
            unit.toLowerCase().includes(search) ||
            driver.toLowerCase().includes(search) ||
            carrier.toLowerCase().includes(search) ||
            order.toLowerCase().includes(search)
          )
        })
      }

      return orders
    }
  },

  created () {
    setInterval(() => {
      this.now = new Date() / 1000
    }, 1000)

    const mode = localStorage.getItem('monitor.mode')

    if (mode) {
      this.currentMode = mode
    }
  },

  methods: {
    zoomIn () {
      if (this.map) {
        this.map.zoomIn()
      }
    },

    zoomOut () {
      if (this.map) {
        this.map.zoomOut()
        console.log(this.map.getCenter())
      }
    },

    closeMap () {
      this.mapDialog = false
      this.map.remove()
      this.map = null
    },

    showInMap (order) {
      this.mapDialog = true

      setTimeout(() => {
        this.renderMap(order)
      }, 500)
    },

    async renderMap (order) {
      this.map = L.map('map', {
        center: L.latLng(8.605414876278534, -80.31278440199613),
        zoom: 8,
        zoomControl: false,
        minZoom: 8
      })

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map)

      this.layer = L.featureGroup()
      this.map.addLayer(this.layer)
      
      const plant = {
        name: order?.plant?.name,
        geofence: order?.plant?.geofence
      }
      const plantCenter = this.drawGeofence(plant)

      const destination = {
        name: order?.destination?.name,
        geofence: order?.destination?.geofence
      }
      const destinationCenter = this.drawGeofence(destination, true)

      if (order.state === 'PENDING') {
        this.remainingPathToDestination = L.polygon([plantCenter, destinationCenter], {
          color: '#00ffff',
          weight: 2,
          opacity: 0.5,
          fillOpacity: 0.2
        })

        this.remainingPathToDestination.addTo(this.layer)
      } else {
        const messages = await this.$store.dispatch('orders/getMessages', {
          unitId: order.unit.pk,
          startAt: order.stage.startAt,
          endAt: new Date() / 1000
        })

        let previousMessage = plantCenter
        let dir = 0
        for (const i in messages) {
          const message = messages[i]
          const center = L.latLng(message.latitude, message.longitude)
          dir = message.direction

          const line = L.polygon([previousMessage, center], {
            color: '#001aff',
            weight: 4,
            opacity: 0.7,
            fillOpacity: 0.7
          })
          previousMessage = center

          line.addTo(this.layer)
        }

        this.drawCar(order.unit, previousMessage, dir)

        this.remainingPathToDestination = L.polygon([previousMessage, destinationCenter], {
          color: '#00ffff',
          weight: 4,
          opacity: 0.7,
          fillOpacity: 0.7
        })

        this.remainingPathToDestination.addTo(this.layer)
      }

      this.map.fitBounds(this.layer.getBounds())
    },

    drawGeofence (plant, isDestination = false) {
      if (plant?.geofence) {
        const geofence = plant.geofence
        const mode = geofence.mode
        
        let shape = null
        if (mode === 'RADIAL') {
          shape = L.circle(L.latLng(geofence.shape[0].latitude, geofence.shape[0].longitude), geofence.shape[0].radius, {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillColor: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillOpacity: 0.2
          })
        } else if (mode === 'POLYGON') {
          shape = L.polygon(geofence.path.map(item => {
            return L.latLng(item.latitude, item.longitude)
          }), {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillColor: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillOpacity: 0.2
          })
        } else {
          shape = L.corridor(geofence.path.map(item => {
            return L.latLng(item.latitude, item.longitude)
          }), {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            opacity: 0.5,
            corridor: geofence.path[0].radius
          })
        }

        shape.addTo(this.layer)

        const html = document.createElement('p')
        html.style.fontSize = '12px'
        html.style.color = '#000'
        html.style.textAlign = 'center'
        html.innerHTML = geofence.name
        html.className = 'map-label'

        const width = geofence.name.length * 8
        html.style.width = width + 'px'

        const icon = L.divIcon({
          iconSize: new L.Point(width, 12),
          className: 'text-center',
          html
        })

        const center = shape.getBounds().getCenter()
        const label = L.marker(center, { icon })
        
        label.addTo(this.layer)

        return center
      }

      return null
    },

    drawCar (unit, center, direction = 0) {
      const html = document.createElement('div')
      html.style.background = 'transparent'
      html.style.display = 'flex'
      html.style.justifyContent = 'center'
      html.style.alignItems = 'center'
      html.style.width = '70px'
      html.style.height = '70px'
      html.style.position = 'relative'

      const arrowContainer = document.createElement('div')
      arrowContainer.style.width = '100%'
      arrowContainer.style.height = '100%'
      arrowContainer.style.position = 'absolute'
      arrowContainer.style.top = '0'
      arrowContainer.style.left = '0'
      arrowContainer.style.display = 'flex'
      arrowContainer.style.justifyContent = 'center'
      arrowContainer.style.alignItems = 'flex-start'
      arrowContainer.style.transform = `rotate(${direction || 0}deg)`

      const arrow = document.createElement('i')
      arrow.className = 'mdi mdi-chevron-up'
      arrow.style.fontSize = '30px'
      arrow.style.padding = 0
      arrow.style.margin = 0
      arrow.style.lineHeight = '15px'
      arrowContainer.appendChild(arrow)

      html.appendChild(arrowContainer)

      const avatar = document.createElement('div')
      avatar.style.background = 'var(--v-primary-base)'
      avatar.style.color = '#ffffff'
      avatar.style.width = '40px'
      avatar.style.height = '40px'
      avatar.style.borderRadius = '50%'
      avatar.style.display = 'flex'
      avatar.style.justifyContent = 'center'
      avatar.style.alignItems = 'center'
      avatar.style.boxShadow = '0 0 0 2px rgba(0,0,0,.1)'
      avatar.style.overflow = 'hidden'

      const content = document.createElement('i')
      content.style.padding = 0
      content.style.margin = 0
      content.style.fontSize = '16px'
      content.className = 'mdi mdi-car-wireless'
      avatar.appendChild(content)
      html.appendChild(avatar)

      const label = document.createElement('p')
      label.style.padding = 0
      label.style.margin = 0
      label.style.position = 'absolute'
      label.style.bottom = '-25px'
      label.style.fontSize = '16px'
      label.style.background = 'rgba(0,0,0,0.6)'
      label.style.color = '#fff'
      label.style.padding = '3px 8px'
      label.style.borderRadius = '30px'

      label.innerHTML = unit.plate
      html.appendChild(label)
      const icon = L.divIcon({
        className: 'asset-icon',
        iconSize: new L.Point(70, 70),
        html
      })
      const marker = L.marker(center, { icon })
      marker.addTo(this.layer)
    },

    async cancelOrder (orderId) {
      const operation = await this.$store.dispatch('orders/clear', orderId)
      this.$store.commit('setLoading', true)

      if (operation) {
        this.$store.commit('toggleSnackbar', {
          message: this.$t('orders.clear.success'),
          icon: 'mdi-check-decagram',
          color: 'green darken-2'
        })
      }
      this.$store.commit('setLoading', false)
    }
  }
}
</script>