<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-btn v-if="currentWindow === 1" icon color="primary" @click="discard">
              <v-icon>mdi-backburger</v-icon>
            </v-btn>
          </v-card-title>

          <v-window v-model="currentWindow">
            <v-window-item :key="0">
              <v-card-text>
                <v-text-field
                  v-model="search"
                  solo
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('helpers.search')"
                  hide-details
                >
                  <template #append>
                    <v-tooltip left>
                      <template #activator="{ on }">
                        <v-btn small icon color="primary" v-on="on" @click="moreFiltersDialog = true">
                          <v-icon>mdi-more</v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('monitor.toolbar.moreFilters.title') }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>

                <v-data-table
                  :items="orders"
                  :headers="headers"
                  :search="search"
                  :items-per-page.sync="itemsPerPage"
                >
                  <template #body="{ items }">
                    <tbody>
                      <tr v-for="item, i in items" :key="`past-orders-row-${i}`">
                        <td>{{ item.pk }}</td>
                        <td>{{ parseDate(item.generatedAt * 1000) }}</td>
                        <td>{{ item.number }}</td>
                        <td>{{ item.plant }}</td>
                        <td>{{ item.destination }}</td>
                        <td>{{ item.state }}</td>
                        <td class="text-right pa-2">
                          <v-btn
                            small
                            icon
                            color="info"
                            @click="show(item.pk)"
                          >
                            <v-icon small>mdi-eye-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-window-item>

            <v-window-item :key="1">
              <v-row>
                <v-col cols="12">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>{{ $t('orders.plant') }}</th>
                        <td>{{ plant }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('orders.destination') }}</th>
                        <td>{{ destination }} ({{ customer }})</td>
                      </tr>
                      <tr>
                        <th>{{ $t('orders.unit') }}</th>
                        <td>{{ unit }} ({{ carrier }})</td>
                      </tr>
                      <tr>
                        <th>{{ $t('orders.driver') }}</th>
                        <td>{{ driver }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>

                <v-col cols="12">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>{{ $t('orders.helpers.stage') }}</th>
                        <th>{{ $t('orders.helpers.startAt') }}</th>
                        <th>{{ $t('orders.helpers.endAt') }}</th>
                        <th>{{ $t('orders.helpers.timeAt') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="state in ['LOADING', 'TRANSIT', 'DELIVERING']" :key="state">
                        <td>{{ $t(`orders.states.${state}`) }}</td>
                        <td>{{ getStartAt(state) }}</td>
                        <td>{{ getEndAt(state) }}</td>
                        <td>{{ getTotalTime(state) }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                
                <v-col cols="12">
                  <div id="detailsMap" style="width: 100%; height: 300px" />
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="moreFiltersDialog"
      persistent
      width="50%"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="moreFilters.byDate"
                :label="$t('monitor.toolbar.moreFilters.byDate')"
                hide-details
              />
            </v-col>

            <v-col v-if="moreFilters.byDate" cols="12">
              <v-date-range
                v-model="moreFilters.dateRange"
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn block color="primary" depressed @click="moreFiltersDialog = false">{{ $t('actions.apply') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { DateRange } from 'vuetify-dates-pickers'
const dayjs = require('dayjs')
export default {
  components: {
    'v-date-range': DateRange
  },

  data () {
    return {
      moreFiltersDialog: false,
      moreFilters: {
        byDate: false,
        dateRange: [
          new Date(),
          new Date()
        ]
      },

      currentWindow: 0,

      map: null,
      layer: null,

      object: {},

      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('orders.pk'), value: 'pk' },
        { text: this.$t('orders.generatedAt'), value: 'generatedAt' },
        { text: this.$t('orders.number'), value: 'number' },
        { text: this.$t('orders.plant'), value: 'plant' },
        { text: this.$t('orders.destination'), value: 'destination' },
        { text: this.$t('orders.state'), value: 'state' },
        { text: '' }
      ]
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    orderNumber () { return this.object?.number || this.$t('helpers.notAvailable') },
    plant () { return this.object?.plant?.name || this.$t('helpers.notAvailable') },
    destination () { return this.object?.destination?.name || this.$t('helpers.notAvailable') },
    customer () { return this.object?.destination?.owner?.name || this.$t('helpers.notAvailable') },
    unit () { return this.object?.unit?.plate || this.$t('helpers.notAvailable') },
    driver () { return this.object?.driver?.name || this.$t('helpers.notAvailable') },
    carrier () { return this.object?.unit?.carrier?.name || this.$t('helpers.notAvailable') },

    title () {
      if (this.currentWindow === 0) {
        return this.$t('orders.title.list')
      }

      return `${this.$t('orders.number')}: ${this.orderNumber}`
    },

    orders () {
      let orders = [
        ...this.$store.getters['orders/delivered'],
        ...this.$store.getters['orders/cancelled']
      ]

      if (this.moreFilters.byDate) {
        const startAt = this.moreFilters.dateRange[0].getTime() / 1000
        const endAt = this.moreFilters.dateRange[1].getTime() / 1000

        orders = orders.filter(item => {
          return item.generatedAt >= startAt && item.generatedAt <= endAt
        })
      }

      return orders.map(item => {
        return {
          pk: item.pk,
          generatedAt: item.generatedAt,
          number: item.number,
          plant: item?.plant?.name || this.$t('helpers.notAvailable'),
          destination: item?.destination?.name || this.$t('helpers.notAvailable'),
          state: this.$t(`orders.states.${item.state}`)
        }
      })
    }
  },

  methods: {
    parseDate (unix) {
      const date = new Date(unix)

      const month = date.getMonth() + 1
      const day = date.getDate()

      return `${day.toString().length > 1 ? day : `0${day}`}/${month.toString().length > 1 ? month : `0${month}`}/${date.getFullYear()}`
    },

    renderMap (order) {
      this.map = L.map('detailsMap', {
        center: L.latLng(8.605414876278534, -80.31278440199613),
        zoom: 8,
        zoomControl: false
      })

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map)

      this.layer = L.featureGroup()
      this.map.addLayer(this.layer)

      console.log(order)

      let plantCenter
      if (order?.plant) {
        plantCenter = this.drawGeofence(order.plant)
      }

      let destinationCenter
      if (order?.destination) {
        destinationCenter = this.drawGeofence(order.destination)
      }

      const path = []

      if (order.messages) {
        order.messages.forEach(message => {
          if (message.latitude && message.longitude) {
            path.push(L.latLng(message.latitude, message.longitude))
          }
        })
      } else {
        path.push(plantCenter)
        path.push(destinationCenter)
      }

      const polyline = L.polyline(path, {
        color: '#001aff',
        weight: 3,
        opacity: 0.5,
        smoothFactor: 1
      })

      polyline.addTo(this.layer)

      this.map.fitBounds(this.layer.getBounds())

      this.map.options.minZoom = this.map.getZoom() + 1
    },

    drawGeofence (plant, isDestination = false) {
      if (plant?.geofence) {
        const geofence = plant.geofence
        const mode = geofence.mode
        
        let shape = null
        if (mode === 'RADIAL') {
          shape = L.circle(L.latLng(geofence.shape[0].latitude, geofence.shape[0].longitude), geofence.shape[0].radius, {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillColor: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillOpacity: 0.2
          })
        } else if (mode === 'POLYGON') {
          shape = L.polygon(geofence.path.map(item => {
            return L.latLng(item.latitude, item.longitude)
          }), {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillColor: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            fillOpacity: 0.2
          })
        } else {
          shape = L.corridor(geofence.path.map(item => {
            return L.latLng(item.latitude, item.longitude)
          }), {
            color: isDestination ? 'var(--v-secondary-base)' : 'var(--v-primary-base)',
            opacity: 0.5,
            corridor: geofence.path[0].radius
          })
        }

        shape.addTo(this.layer)

        const html = document.createElement('p')
        html.style.fontSize = '12px'
        html.style.color = '#000'
        html.style.textAlign = 'center'
        html.innerHTML = geofence.name
        html.className = 'map-label'

        const width = geofence.name.length * 8
        html.style.width = width + 'px'

        const icon = L.divIcon({
          iconSize: new L.Point(width, 12),
          className: 'text-center',
          html
        })

        const center = shape.getBounds().getCenter()
        const label = L.marker(center, { icon })
        
        label.addTo(this.layer)

        return center
      }

      return null
    },

    getTotalTime (stage) {
      const startAt = this.getStartAt(stage, true)
      const endAt = this.getEndAt(stage, true)

      if (startAt === null || endAt === null) {
        return this.$t('helpers.notAvailable')
      }

      const diff = parseInt((endAt - startAt) / 60)

      const days = parseInt((diff / 60) / 24).toString().padStart(2, '0')
      const hours = parseInt((diff / 60) % 24).toString().padStart(2, '0')
      const minutes = parseInt(diff % 60).toString().padStart(2, '0')

      return `${days}D ${hours}H ${minutes}M`
    },

    getStartAt (state, returnUnix = false) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (this.object.stage) {
        if (this.object.stage[field]) {
          if (this.object.stage[field].startAt) {
            if (returnUnix) return this.object.stage[field].startAt 
            return this.parseUnix(this.object.stage[field].startAt * 1000)
          }
        }
      }
      if (returnUnix) return null
      return this.$t('helpers.notAvailable')
    },

    getEndAt (state, returnUnix = false) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (this.object.stage) {
        if (this.object.stage[field].endAt) {
          if (returnUnix) return this.object.stage[field].endAt
          return this.parseUnix(this.object.stage[field].endAt * 1000)
        }
      }
      if (returnUnix) return null
      return this.$t('helpers.notAvailable')
    },

    parseUnix (unix) {
      return dayjs(unix).format('DD/MM/YYYY hh:mm:ss A')
    },

    async show (pk) {
      await this.$store.commit('setLoading', true)
      const order = this.$store.state.orders.list.find(item => item.pk === pk)

      if (order.state === 'DELIVERED') {
        const messages = await this.$store.dispatch('orders/getMessages', {
          unitId: order.unit.pk,
          startAt: order.stage.startAt,
          endAt: order.stage.endAt
        })

        order.messages = messages
      }

      this.object = order

      this.currentWindow = 1

      setTimeout(() => {
        this.renderMap(order)
      }, 0)
      await this.$store.commit('setLoading', false)
    },

    discard () {
      this.map.remove()
      this.map = null
      this.layer = null

      this.object = {}
      this.currentWindow = 0
    }
  }
}
</script>