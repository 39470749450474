var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"13%"}},[_vm._v(_vm._s(_vm.$t('orders.headers.number')))]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.$t('orders.headers.loading')))]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.$t('orders.headers.transit')))]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.$t('orders.headers.delivering')))]),_c('th',{staticClass:"text-center",staticStyle:{"width":"12%"}},[_vm._v(_vm._s(_vm.$t('orders.headers.total')))])])]),_c('tbody',_vm._l((_vm.orders),function(order){return _c('tr',{key:("order-" + (order.pk))},[_c('td',{staticClass:"text-center",staticStyle:{"line-height":"15px"}},[_c('b',[_vm._v(_vm._s(order.number))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.getCarrier(order)))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.getCustomer(order)))])]),_c('td',{staticStyle:{"height":"80px","padding":"1px"}},[_c('div',{class:{
            'white--text text-center d-flex flex-column align-center justify-center': true,
            'grey darken-2': _vm.getStageStatus(order, 'LOADING') === 'PENDING',
            'primary': _vm.getStageStatus(order, 'LOADING') === 'STARTED',
            'secondary': _vm.getStageStatus(order, 'LOADING') === 'FINISHED',
          },staticStyle:{"line-height":"15px","height":"100%"}},[_c('b',[_vm._v(_vm._s(_vm.calculateTime(order, 'LOADING')))]),_c('small',[(!_vm.hasPlantGeofence(order))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white","x-small":""}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.geofenceNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getPlant(order))+" ")],1)])]),_c('td',{staticStyle:{"height":"80px","padding":"1px"}},[_c('div',{class:{
            'white--text text-center d-flex flex-column align-center justify-center': true,
            'grey darken-2': _vm.getStageStatus(order, 'TRANSIT') === 'PENDING',
            'primary': _vm.getStageStatus(order, 'TRANSIT') === 'STARTED',
            'secondary': _vm.getStageStatus(order, 'TRANSIT') === 'FINISHED',
          },staticStyle:{"line-height":"15px","height":"100%"}},[_c('b',[_vm._v(_vm._s(_vm.calculateTime(order, 'TRANSIT')))]),_c('small',[(!_vm.hasUnit(order))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white","x-small":""}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.unitNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getUnit(order))+" ")],1)])]),_c('td',{staticStyle:{"height":"80px","padding":"1px"}},[_c('div',{class:{
            'white--text text-center d-flex flex-column align-center justify-center': true,
            'grey darken-2': _vm.getStageStatus(order, 'DELIVERING') === 'PENDING',
            'primary': _vm.getStageStatus(order, 'DELIVERING') === 'STARTED',
            'secondary': _vm.getStageStatus(order, 'DELIVERING') === 'FINISHED',
          },staticStyle:{"line-height":"15px","height":"100%"}},[_c('b',[_vm._v(_vm._s(_vm.calculateTime(order, 'DELIVERING')))]),_c('small',[(!_vm.hasDestinationGeofence(order))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white","x-small":""}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('errors.geofenceNotLinked')))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getDestination(order))+" ")],1)])]),_c('td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(_vm.calculateFullTime(order, _vm.stages)))]),_c('br'),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('show-map', order)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map")])],1),(_vm.session.level === 'ADMIN')?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('cancel-order', order.pk)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle-outline")])],1):_vm._e()],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }