<template>
  <v-card elevation="1">
    <v-card-title>
      {{ order.number }}
    </v-card-title>

    <v-card-subtitle v-if="session.level !== 'USER'" class="d-flex flex-column align-start">
      <div class="d-flex justify-center">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">{{ carrier }}</small>
          </template>
          <span>{{ $t('orders.carrier') }}</span>
        </v-tooltip>

        <v-divider vertical class="mx-1 my-1" />

        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">{{ customer }}</small>
          </template>
          <span>{{ $t('orders.customer') }}</span>
        </v-tooltip>

        <v-divider vertical class="mx-1 my-1" />

        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">
              <v-tooltip v-if="!hasUnit" bottom>
                <template #activator="{ on: on2 }">
                  <v-icon color="red" x-small v-on="on2">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.unitNotLinked') }}</span>
              </v-tooltip>
              {{ unit }}
            </small>
          </template>
          <span>{{ $t('orders.unit') }}</span>
        </v-tooltip>

        <v-divider vertical class="mx-1 my-1" />

        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">{{ driver }}</small>
          </template>
          <span>{{ $t('orders.driver') }}</span>
        </v-tooltip>
      </div>

      <div class="d-flex justify-center">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">
              <v-tooltip v-if="!hasPlantGeofence" bottom>
                <template #activator="{ on: on2 }">
                  <v-icon color="red" x-small v-on="on2">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.geofenceNotLinked') }}</span>
              </v-tooltip>
              {{ plant }}
            </small>
          </template>
          <span>{{ $t('orders.plant') }}</span>
        </v-tooltip>

        <v-divider vertical class="mx-1 my-1" />

        <v-tooltip bottom>
          <template #activator="{ on }">
            <small style="font-size: 12px;" v-on="on">
              <v-tooltip v-if="!hasDestinationGeofence" bottom>
                <template #activator="{ on: on2 }">
                  <v-icon color="red" x-small v-on="on2">mdi-alert</v-icon>
                </template>

                <span>{{ $t('errors.geofenceNotLinked') }}</span>
              </v-tooltip>
              {{ destination }}
            </small>
          </template>
          <span>{{ $t('orders.destination') }}</span>
        </v-tooltip>
      </div>
    </v-card-subtitle>

    <v-card-subtitle v-else class="d-flex">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <small style="font-size: 12px;" v-on="on">{{ destination }}</small>
        </template>
        <span>{{ $t('orders.destination') }}</span>
      </v-tooltip>
    </v-card-subtitle>

    <v-divider class="mx-2" />

    <v-card-text>
      <v-row v-if="order.state !== 'PENDING'">
        <v-col
          v-for="stage in stages"
          :key="`order-${order.pk}-stage-${stage}`"
          cols="12"
          sm="6"
          md="4"
          class="text-center"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="text-center ma-0 pa-0" v-html="generateLabel(stage)" />

          <template v-if="getStageStatus(stage) === 'PENDING'">
            <v-icon size="60">mdi-progress-clock</v-icon>
          </template>

          <template v-else-if="getStageStatus(stage) === 'STARTED'">
            <v-progress-circular
              indeterminate
              size="50"
              width="10"
              style="margin: 5px 0;"
              color="secondary"
            />
          </template>

          <template v-else>
            <v-icon color="success" size="60">mdi-check-circle-outline</v-icon>
          </template>

          <p>{{ calculateTime(stage) }}</p>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <h3 class="text-center">{{ $t('orders.waitingToStart') }}</h3>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mx-2" />

    <v-card-actions>
      <v-chip v-if="order.state !== 'PENDING'" label small color="secondary black--text">
        <v-icon small class="mr-1">mdi-clock-outline</v-icon>
        {{ calculateFullTime(stages) }}
      </v-chip>

      <v-spacer />

      <v-btn v-if="session.level !== 'USER'" icon color="primary" @click="$emit('show-map', order)">
        <v-icon>mdi-map</v-icon>
      </v-btn>

      <v-btn v-if="session.level === 'ADMIN'" icon color="primary" @click="$emit('cancel-order', order.pk)">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    now: {
      type: Number,
      default: () => new Date() / 1000
    }
  },

  computed: {
    session () { return this.$store.state.session },

    hasPlantGeofence () { return !!this.order?.plant?.geofence?.pk },
    hasDestinationGeofence () { return !!this.order?.destination?.geofence?.pk },
    hasUnit () { return !!this.order?.unit?.ident },

    carrier () { return this.order?.unit?.carrier?.name || this.$t('helpers.notAvailable') },
    customer () { return this.order?.destination?.owner?.name || this.$t('helpers.notAvailable') },
    plant () { return this.order?.plant?.name || this.$t('helpers.notAvailable') },
    destination () { return this.order?.destination?.name || this.$t('helpers.notAvailable') },
    unit () { return this.order?.unit?.plate || this.$t('helpers.notAvailable') },
    driver () { return this.order?.driver?.name || this.$t('helpers.notAvailable') },

    stages () {
      return ['LOADING', 'TRANSIT', 'DELIVERING']
    }
  },

  methods: {
    generateLabel (state) {
      if (this.session.level === 'USER') {
        switch (state) {
          case 'LOADING':
            return this.$t('orders.headers.loading')
          case 'TRANSIT':
            return this.$t('orders.headers.transit')
          case 'DELIVERING':
            return this.$t('orders.headers.delivering')
          default:
            return this.$t('orders.waitingToStart')
        }
      }
      switch (state) {
        case 'LOADING':
          return this.$t('orders.stages.LOADING', { name: this.order.plant?.name || this.$t('helpers.notAvailable') })
        case 'TRANSIT':
          return this.$t('orders.stages.TRANSIT', { name: this.order.destination?.name || this.$t('helpers.notAvailable') })
        case 'DELIVERING':
          return this.$t('orders.stages.DELIVERING', { name: this.order.destination?.name || this.$t('helpers.notAvailable') })
        default:
          return this.$t('orders.waitingToStart')
      }
    },

    getStageStatus (state) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (!this.order?.stage) {
        return 'PENDING'
      }
      
      if (!this.order.stage[field]) {
        return 'PEDNING'
      }

      if (this.order.stage[field].endAt) {
        return 'FINISHED'
      }
      if (this.order.stage[field].startAt) {
        return 'STARTED'
      }
      return 'PENDING'
    },

    calculateTime (state, getTime = false) {
      let field = 'loading'

      if (state === 'TRANSIT') {
        field = 'transit'
      } else if (state === 'DELIVERING') {
        field = 'delivering'
      }

      if (!this.order?.stage) {
        if (getTime) {
          return 0
        }
        return '00D 00H 00M'
      }
      
      if (!this.order.stage[field]) {
        if (getTime) {
          return 0
        }
        return '00D 00H 00M'
      }

      if (this.order.stage[field].endAt) {
        const diff = Math.round(parseInt(this.order.stage[field].endAt - this.order.stage[field].startAt) / 60)

        const days = parseInt((diff / 60) / 24).toString().padStart(2, '0')
        const hours = parseInt((diff / 60) % 24).toString().padStart(2, '0')
        const minutes = parseInt(diff % 60).toString().padStart(2, '0')

        if (getTime) {
          return diff
        }
        return `${days}D ${hours}H ${minutes}M`
      }
      if (this.order.stage[field].startAt) {
        const diff = Math.round(parseInt(this.now - this.order.stage[field].startAt) / 60)

        const days = parseInt((diff / 60) / 24).toString().padStart(2, '0')
        const hours = parseInt((diff / 60) % 24).toString().padStart(2, '0')
        const minutes = parseInt(diff % 60).toString().padStart(2, '0')

        if (getTime) {
          return diff
        }
        return `${days}D ${hours}H ${minutes}M`
      }
      if (getTime) {
        return 0
      }
      return '00D 00H 00M'
    },

    calculateFullTime (stages) {
      let time = 0
      for (const i in stages) {
        time += this.calculateTime(stages[i], true)
      }

      const days = parseInt((time / 60) / 24).toString().padStart(2, '0')
      const hours = parseInt((time / 60) % 24).toString().padStart(2, '0')
      const minutes = parseInt(time % 60).toString().padStart(2, '0')

      return `${days}D ${hours}H ${minutes}M`
    }
  }
}
</script>